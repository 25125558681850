import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import { childrenWrapper, errorBox, loader } from './user-order.module.scss';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { IPage } from '../../models/page.model';
import { ISection } from '../../models/section.model';
import { INavLink } from '../../models/nav-link.model';
import { getNodes } from '../../utils/get-nodes';
import { useResale } from '../../hooks/use-resale';

import UserLayout from '../../layouts/user-layout';
import HistoryItemHeader from '../molecules/history-item-header';
import Loader from '../atoms/loader';
import HistoryItemDetails from '../organisms/history-item-details';

export interface IUserResaleSection extends ISection {
    items: {
        links: INavLink[];
    };
}

export interface IUserResaleProps {
    className?: string;
    section: IUserResaleSection;
    TitleTag: React.ElementType;
}

interface IUserResaleStaticQueryResult {
    allResaleHistoryPage: IQueryAllResult<Pick<IPage, 'pathname' | 'locale'>>;
}

const UserResale = ({ section, className = '', TitleTag = 'h2' }: IUserResaleProps) => {
    const { locale } = usePageContext();
    const { allResaleHistoryPage } = useStaticQuery<IUserResaleStaticQueryResult>(query);
    const resaleHistoryPage = getNodes(allResaleHistoryPage).find((page) => page.locale === locale);

    const { sectionId, css, style } = section;
    const [subtitle] = section.content.texts;
    const { links } = section.items;

    const resale = useResale({ queries: ['single'] });
    const singleResale = resale.single.data;

    return (
        <UserLayout
            className={className}
            links={links}
            childrenWrapperClassName={childrenWrapper}
            css={css}
            style={style}
            sectionId={sectionId}
        >
            {resale.single.isLoading && <Loader className={loader} />}
            {!resale.single.isLoading && (
                <>
                    {singleResale && (
                        <HistoryItemHeader
                            title={`${subtitle} ${singleResale?.uid}`}
                            returnPath={resaleHistoryPage?.pathname}
                            item={singleResale}
                            TitleTag={TitleTag}
                        />
                    )}
                    {resale.single.isError && (
                        <div className={errorBox}>
                            {resale.single.errors?.map((error) => {
                                return <p>{error.content}</p>;
                            })}
                        </div>
                    )}
                    {resale.single.isSuccess && singleResale && (
                        <HistoryItemDetails item={singleResale} />
                    )}
                </>
            )}
        </UserLayout>
    );
};

export default UserResale;

const query = graphql`
    query {
        allResaleHistoryPage: allPage(filter: { type: { eq: "resale-history" } }) {
            edges {
                node {
                    locale
                    pathname
                }
            }
        }
    }
`;
